import React, { useContext, createContext } from 'react';

const authContext = createContext({
  loggedIn:     () => {}, // to check if authenticated or not
  user:         {},       // store all the user details
  login:        () => {}, // to start the login process
  authenticate: () => {}, // handle Auth0 login process
  logout:       () => {}, // logout the user,
  protect:      () => {}, // privilege the functions
});

export const useAuthContext = () => useContext(authContext);

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;

export function withInternalAuthContext(Component) {
  return function ComponentWithContext(props) {
    return (
      <AuthConsumer>
        {authContext => <Component {...props} authContext={authContext} />}
      </AuthConsumer>
    );
  };
}