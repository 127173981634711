// @flow
import { default as mlb } from './../static/mlb.json'
import { default as nfl } from './../static/nfl.json'
import { default as nba } from './../static/nba.json'
import { default as nhl } from './../static/nhl.json'

export const staticGames = {
mlb,
nfl,
nba,
nhl
}

export const dropdownItems = [
  'mlb',
  'nba',
  'nfl',
  'nhl',
];

