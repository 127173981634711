export const getSectionFromPayloadName = (payloadName) => {
  const prefix = payloadName.substr(0, 3);

  switch (prefix) {
    case 'mlb': {
      return 'phillies';
    }
    case 'mls': {
      return 'soccer';
    }
    case 'nba': {
      return 'sixers';
    }
    case 'nhl': {
      return 'flyers';
    }
    case 'nfl': {
      return 'eagles';
    }
    default: {
      return '';
    }
  }
};