import React, { Component } from 'react';

import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';

import './styles/App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from './shared/Header';
import Footer from './shared/Footer';
import LandingPage from './views/LandingPage';
import AlertTemplate from './components/common/AlertTemplate';
import { tabs, RouteWithSubRoutes } from './routes';
import { AuthConsumer } from "./components/auth/AuthContext";
import Auth from "./components/auth/Auth";
import Callback from './components/auth/Callback';
import { ThemeProvider } from 'styled-components';
import mainTheme from './theme/main';

// cofiguration for global alert
const options = {
  position: 'top right',
  timeout: 4000,
  offset: '30px',
  transition: 'scale',
}

const RenderLanding = () => {
  return (
    <div>
      <LandingPage />
      <RouteWithSubRoutes path='/callback' component={Callback} />
    </div>
  );
};

const RenderApp = withRouter (
  ({ history, location }) => {
    const isSwipeable = location.pathname.includes("swipeable");

    return (
      <Container className={isSwipeable ? "swipeable-container" : ""}>
        <Row>
          <Col className="App-header">
            <Header path={location.pathname} />
          </Col>
        </Row>
        <Row>
          <Col className="App-body">
            {tabs.map((route, idx) => <RouteWithSubRoutes key={idx} {...route} />)}
          </Col>
        </Row>
        <Row className="App-footer">
          <Col className="App-footer">
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
);

const CheckAuth = () => {
  return (
    <AuthConsumer>
      {({ loggedIn }) => loggedIn() ? <RenderApp /> : <RenderLanding /> }
    </AuthConsumer>
  );
};

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={mainTheme}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Router>
            <div className="App">
              <Auth>
                <CheckAuth />
              </Auth>
            </div>
          </Router>
        </AlertProvider>
      </ThemeProvider>
    );
  }
}

export default App;
