import auth0 from "auth0-js";

import { setToken, isTokenValid, removeToken, getProfile, getTokenExpiry } from '../utils/auth-jwt';
import { getData } from '../utils/fetch-api';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

class AuthService {

  constructor(domain) {
    this.auth = new auth0.WebAuth({
      domain:       `${process.env.REACT_APP_AUTH_DOMAIN}`,
      clientID:     `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
      redirectUri:  `${process.env.REACT_APP_AUTH_CALLBACK_URL}`,
      responseType: 'token id_token',
    });
  }

  login() {
    this.auth.authorize();
  }

  loggedIn() {
    return isTokenValid();
  }

  async getUserProfile() {
    const profile = getProfile();
    const role = await getData(`${baseUrl}/auth/role`);
    return { ...profile, role };
  }

  logout() {
    removeToken();
    return this.auth.logout({
      returnTo: `${process.env.REACT_APP_AUTH_LOGOUT_URL}`,
      clientID: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
      federated: true,
    });
  }

  authenticate(callback) {
    this.auth.parseHash(async (error, authResult) => {
      if (error) {
        console.error(`Error ${error.error} occured`);
        return;
      }

      setToken(authResult.idToken);
      if (callback) {
        const profile = await this.getUserProfile();
        callback(profile);
      }
    });
  }

  renewSession() {
    this.auth.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setToken(authResult.idToken);
      } else if (err) {
        this.logout();
        console.error(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  getTokenExpiration() {
    return getTokenExpiry();
  }
}

const authService = new AuthService();

export default authService;

